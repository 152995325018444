import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button } from 'Components/Button';

import { PopupParent } from 'Pages/FigmaPluginAuth/components/PopupParent';

import styles from './styles.module.scss';

export const AuthModel = ({ icons, title, description, button, formData, hideNav }) => (
  <PopupParent hideNav={hideNav} >
    <div className={cn('flex flex-column align-center just-center', styles['parent'])}>
      <div className={styles['icons']} >
        {icons}
      </div>
      <div className={styles['modal-content-container']}>
        <span className={styles['modal-title']}>
          {title}
        </span>
        <span className={styles['modal-description']}>
          {description}
        </span>
      </div>
      {formData}
      {button
        && (
          <Button
            className={styles['modal-button']}
            onClick={button.onClick}
            loading={button.loading}
            disabled={button.disabled}
          >
            {button.text}
          </Button>
        )}
    </div>
  </PopupParent>
);

AuthModel.propTypes = {
  icons: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([ PropTypes.string, PropTypes.node]).isRequired,
  button: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
  formData: PropTypes.node,
  hideNav: PropTypes.bool,
};
