import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './landing.scss';

import { ROUTES } from 'routes/route.constants';

import { identifyUser } from 'services/eventTracking';

import { checkingUserType } from 'utlis/globalFunctions';

import { InstallExtensionButton } from './components/InstallExtensionButton';
import { InstallPluginButton } from './components/InstallPluginButton';
import { LandingPageUI } from './components/LandingPageUI';
import { LoginButton } from './components/LoginButton';

const Landing = () => {
  const navigate = useNavigate();
  const userProfile = useSelector(state => state.userProfile);

  useEffect(() => {
    if (window.localStorage.getItem('authenticated')) {
      navigate(ROUTES.LAUNCH_PLUGIN_EXTENSION_PAGE);
      checkingUserType(userProfile, navigate);
    }
    else {
      navigate(ROUTES.LANDING);
    }
  }, [userProfile, navigate]);

  useEffect(() => {
    identifyUser(userProfile.uuid);
  }, [userProfile]);

  const installButton = (
    <InstallPluginButton />
  );

  if (window?.localStorage.getItem('authenticated')) {
    return <LoginButton attemptLogin />;
  }

  const installButton2 = (
    <InstallExtensionButton />
  );

  return (
    <LandingPageUI installButton={installButton} InstallExtensionButton={installButton2} />
  );
};

export default Landing;
