import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import designProLogo from 'assets/designProLogo.svg';

import { AuthModel } from 'Pages/FigmaPluginAuth/components/AuthModel';

import { ROUTES } from 'routes/route.constants';

import { PLUGIN_SOURCE } from 'utlis/constants';

import styles from './styles.module.scss';

export const LaunchPluginExtensionPage = () => {
  const [descriptionText, setDescriptionText] = useState('');
  const [userObject, setUserObject] = useState(null);
  const navigate = useNavigate();

  const chromeExtensionUrl = process.env.REACT_APP_REVIEWER_CHROME_EXTENSION_URL;
  const figmaPluginUrl = process.env.REACT_APP_REVIEWER_PLUGIN_URL;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userObject'));

    setUserObject(user);
  }, []);

  useEffect(() => {
    if (!userObject) {
      return;
    }

    if (!userObject?.plugin_source?.length) {
      navigate(ROUTES.DASHBOARD_PROJECT_TYPE('active'));

      return;
    }
    const pluginSource = userObject.plugin_source;

    if (
      pluginSource.includes(PLUGIN_SOURCE.designReviewer)
      && pluginSource.includes(PLUGIN_SOURCE.chromeExtension)
    ) {
      setDescriptionText(
        <>
          Launch the
          {' '}
          <a href={figmaPluginUrl} target="_blank" rel="noopener noreferrer">
            Figma Plugin
          </a>
          {' '}
          or
          {' '}
          <a href={chromeExtensionUrl} target="_blank" rel="noopener noreferrer">
            Chrome Extension
          </a>
          {' '}
          to enhance your Figma designs and websites.
        </>
      );
    }
    else if (pluginSource.includes(PLUGIN_SOURCE.designReviewer)) {
      setDescriptionText(
        <>
          Launch the
          {' '}
          <a href={figmaPluginUrl} target="_blank" rel="noopener noreferrer">
            Figma Plugin
          </a>
          {' '}
          to enhance your Figma designs.
        </>
      );
    }
    else if (pluginSource.includes(PLUGIN_SOURCE.chromeExtension)) {
      setDescriptionText(
        <>
          Launch the
          {' '}
          <a href={chromeExtensionUrl} target="_blank" rel="noopener noreferrer">
            Chrome Extension
          </a>
          {' '}
          to enhance your Websites.
        </>
      );
    }
    else {
      navigate(ROUTES.DASHBOARD_PROJECT_TYPE('active'));
    }
  }, [userObject]);

  return (
    <AuthModel
      icons={<img src={designProLogo} alt="Design Pro Logo" className={styles['icon']} />}
      title="You're all set!"
      description={descriptionText}
    />
  );
};
