import { MILESTONES_TYPES } from 'utlis/constants';

export const ROUTES = {
  NO_MATCH: '*',
  LANDING: '/',
  LAUNCH_PLUGIN_EXTENSION_PAGE: '/dashboard',
  PAYMENT_COMPLETE: '/payment-complete',
  SLACK_AUTH_RETURN: '/slack-auth-complete',
  FIGMA_PLUGIN_AUTH: '/figma-plugin-auth',
  CREATE_NEW_PROJECT: '/figma-plugin-auth/new-project',
  CONNECT_TO_FIGMA: '/figma-plugin-auth/connect-to-figma',
  CONNECT_TO_JIRA: '/figma-plugin-auth/connect-to-jira',
  CONNECT_TO_SLACK: '/figma-plugin-auth/connect-to-slack',
  VERIFY_EMAIL: '/figma-plugin-auth/verify-email',
  FORGOT_PASSWORD: '/figma-plugin-auth/forgot-password',
  AUTH_COMPLETE: '/figma-plugin-auth/complete',
  REVIEWER_AUTH_COMPLETE: '/reviewer-auth-complete',
  EXTENSION_AUTH_COMPLETE: '/extension-auth-complete',
  GDOC_AUTH_COMPLETE: '/gdoc-auth-complete',
  MANUAL_TASK_AUTH_COMPLETE: '/manual-task-auth-complete',
  TRANSCRIPT_AUTH_COMPLETE: '/transcript-auth-complete',
  JIRA_AUTH_COMPLETE: 'jira-auth-complete',
  SLACK_AUTH_COMPLETE: 'slack-auth-complete',
  CLIENT_LOGIN: '/client-login',
  LANDING_READONLY: readonlyUUID => `/view-project/${readonlyUUID}`,
  HOME: '/projects',
  DASHBOARD: '/projects',
  DASHBOARD_PROJECT_TYPE: (projectType = 'active') => `/projects/${projectType}`,
  PROJECT_DETAILS: projectSlug => `/project-details/${projectSlug}`,
  FEED: projectSlug => `/project-details/${projectSlug}/feed`,
  BOARD: projectSlug => `/project-details/${projectSlug}/board`,
  WORKSPACE: projectSlug => `/project-details/${projectSlug}/workspace`,
  PAYMENTS: projectSlug => `/project-details/${projectSlug}/payments`,
  PROJECT_MILESTONES: getProjectMilestonesUrl,

  BRIEF: (projectSlug, milestoneId) => getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.designBrief, milestoneId),
  PROPOSAL: (projectSlug, milestoneId) => getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.proposal, milestoneId),
  CONTRACT: (projectSlug, milestoneId) => getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.contract, milestoneId),
  MOODBOARD: (projectSlug, milestoneId) => (
    getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.moodboard, milestoneId)
  ),
  DESIGN_CONCEPTS: (projectSlug, milestoneId) => (
    getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.designConcepts, milestoneId)
  ),
  PRESENTATION: (projectSlug, milestoneId) => (
    getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.presentation, milestoneId)
  ),
  DELIVERY: (projectSlug, milestoneId) => (
    getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.deliverFiles, milestoneId)
  ),
  USER_INTERVIEW: (projectSlug, milestoneId) => (
    getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.userInterview, milestoneId)
  ),
  DESIGN_REVIEW: (projectSlug, milestoneId) => (
    getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.designReview, milestoneId)
  ),
  SKETCHES: (projectSlug, milestoneId) => (
    getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.sketches, milestoneId)
  ),
  BRAND_PERSONALITY: (projectSlug, milestoneId) => (
    getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.brandPersonality, milestoneId)
  ),
  COLOR_PALETTE: (projectSlug, milestoneId) => (
    getProjectMilestonesUrl(projectSlug, MILESTONES_TYPES.colorPalette, milestoneId)
  ),

  ON_BOARDING: '/onboarding',
  SETTINGS: '/settings',
  PROFILE_SETTINGS: '/settings/profile',
  CONNECTED_APPS_SETTINGS: '/settings/connected-apps',
  LANGUAGE_AND_TIMEZONE_SETTINGS: '/settings/language-and-timezone',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  SESSION_EXPIRED: '/session-has-expired',
};

function getProjectMilestonesUrl(projectSlug, milestoneType, milestoneId) {
  return `/project-details/${projectSlug}/workspace/${milestoneType}/${milestoneId}`;
}
